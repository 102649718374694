const CogsIcon = "cogs-icon.svg"
const GoogleIcon = "google-icon.svg"
const LaptopIcon = "laptop-icon.svg"
const PaperIcon = "paper-icon.svg"
const SearchIcon = "search-icon.svg"
const TagsIcon = "tags-icon.svg"
const WheelchairIcon = "wheelchair-icon.svg"

interface ServiceItem {
  icon: string
  title: string
  body: string
}

export const servicesList: ServiceItem[] = [
  {
    icon: LaptopIcon,
    title: "Design Updates",
    body:
      "Anytime you need update to your site or a new design, we'll rebuild your site at no extra cost.",
  },
  {
    icon: SearchIcon,
    title: "Search Engine Optimization",
    body:
      "We make sure your website stays up-to-date with Google's search ranking algorithms.",
  },
  {
    icon: WheelchairIcon,
    title: "Accessibility",
    body:
      "We make sure your site is accessible to everyone on the web, ensuring it's ADA compliant.",
  },
  {
    icon: GoogleIcon,
    title: "Google Business Profile",
    body: "We help optimize and manage your Google and Bing Business Profiles.",
  },
  {
    icon: PaperIcon,
    title: "Content Creation",
    body: "We help create content tailored for the web and social media.",
  },
  {
    icon: TagsIcon,
    title: "Google Ads",
    body:
      "We help manage and monitor pay per click ads that drive customers to your business.",
  },
  {
    icon: CogsIcon,
    title: "Automation",
    body:
      "We discover ways to limit redundancy in your business by automating and connecting services.",
  },
]
