import React from "react"
import tw from "twin.macro"
import { Package } from "./package"
import { PACKAGES } from "../constants"

const Prices = tw.div`
  flex
  flex-row
  flex-wrap
  m-auto
  justify-center
  md:mt-10
  z-50
`

export const Packages = () => {
  return (
    <Prices>
      {Object.keys(PACKAGES).map((key, i) => {
        const {
          title,
          icon,
          features,
          price,
          isMonthly,
          alt,
          description,
        } = PACKAGES[key]
        return (
          <Package
            key={key}
            title={title}
            icon={icon}
            features={features}
            price={price}
            description={description}
            isMonthly={isMonthly}
            index={i}
            alt={alt}
          />
        )
      })}
    </Prices>
  )
}
