import React from "react"
import { Card } from "./page-elements/card"
import tw, { styled, css } from "twin.macro"
import { H3, P, Button } from "../components/page-elements"
import CircleCheck from "../images/circle-check.svg"

interface PackageProps {
  title: string
  features: string[]
  price: number
  icon: string
  alt: string
  isMonthly?: boolean
  index: number
  description: string
}

const Icon = tw.img`
    flex-1
    w-40
    h-40
    max-w-xl
`

const Items = tw.div`
    flex
    flex-col
    flex-1
    w-full
    items-start
    justify-start
    mt-2
`

const Item = tw.div`
    flex
    flex-row
    items-center
    my-2
`

const Price = tw.div`
  relative
  w-full
  text-center
  text-primary
`

const Container = tw.div`
    flex
    flex-col
    lg:flex-1
    items-center
    max-w-xl
    transform
    bg-white  
    rounded
    shadow-full
    mx-2
    my-2
    md:my-0
    p-4
  `

const StyledButton = styled(Button)`
  ${tw`w-full text-center capitalize z-50`}
`

const StyledH3 = styled(H3)`
  ${tw`text-center text-header w-full`}
`

const CheckMark = tw.img`
  w-4
`

export const Package: React.FC<PackageProps> = ({
  title,
  features,
  price,
  icon,
  alt,
  description,
  isMonthly = true,
  index,
}) => {
  return (
    <Container>
      <div className="flex flex-col items-center">
        <Icon src={icon} alt={alt} />

        <StyledH3>{title}</StyledH3>

        <Price>
          {/* {!isMonthly && (
            <span className="absolute m-auto text-center right-0 left-0 text-md text-body uppercase">
              Starting At
            </span>
          )} */}
          <span className="text-4xl ">${price}</span>
          <span className="">/Month</span>
        </Price>

        <p className="text-body my-2">{description}</p>
      </div>

      <div className="w-full flex flex-col justify-between h-full">
        <Items>
          {features.map(item => {
            return (
              <Item key={item}>
                <CheckMark
                  src={CircleCheck}
                  className="mr-2"
                  alt="Circled checkmark"
                />
                <P className="text-body" style={{ marginTop: 0 }}>
                  {item}
                </P>
              </Item>
            )
          })}
        </Items>
        <StyledButton to="/contact">Choose Plan</StyledButton>
      </div>
    </Container>
  )
}
