import StandardIcon from "../images/standard-icon.svg"
import StandardBlogginIcon from "../images/standard-blogging-icon.svg"
import CityIcon from "../images/city.svg"
import SpaceIcon from "../images/space.svg"
import TownIcon from "../images/town.svg"

export const PACKAGES: { [key: string]: any } = {
  PROFESSIONAL: {
    title: "Essential",
    description: "For most business that need high quality web presence.",
    price: 150,
    features: [
      "5 Pages Website",
      "Responsive Design",
      "Unlimited Edits",
      "Website Hosting",
      "24/7 Support",
      "Lifetime Updates",
      "SEO Optimization",
      "Custom Content",
    ],
    icon: StandardIcon,
    alt: "House Icon",
    isMonthly: true,
  },
  PROFESSIONAL_BLOGGING: {
    title: "Professional",
    description: "Great for boosting SEO with your own blog content.",
    price: 175,
    features: ["Essential+", "Blog Page", "Boosts SEO", "Add Blogs Anytime"],
    icon: StandardBlogginIcon,
    alt: "Building Icon",
    isMonthly: true,
  },
  PREMIUM: {
    title: "Premium",
    description:
      "We write monthly blog posts increase your SEO while you focus on your business.",
    price: 275,
    features: [
      "Professional+",
      "Monthly Blog",
      "1000 Word Post",
      "Written By SEO Specialist",
      "Boost SEO",
    ],
    icon: CityIcon,
    alt: "Building Icon",
    isMonthly: true,
  },
  OUTOFMIND: {
    title: "Out of Mind",
    description:
      "For those that truly don’t want to deal with this. We’ll take care of everything.",
    price: "650 + $275",
    features: [
      "Premium+",
      "Content Written by Specialist ($300)",
      "Logos & Branding (Starting at $350)",
    ],
    icon: SpaceIcon,
    alt: "Space",
    isMonthly: true,
  },
  // LOGOS_BRANDING: {
  //   title: "Logos & Branding",
  //   price: 350,
  //   features: [
  //     "2 revisions",
  //     "All File Formats",
  //     "Light & Dark Versions",
  //     "Horizontal & Verticle",
  //   ],
  //   icon: LogosBranding,
  //   alt: "Women looking at images of houses.",
  //   isMonthly: false,
  // },
}
